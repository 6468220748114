
import { defineComponent, ref } from 'vue'
import { CountTo } from 'vue3-count-to'
import messages from '@/assets/images/home/messages.png'
import purchases from '@/assets/images/home/purchases.png'
import shoppings from '@/assets/images/home/shoppings.png'
import visits from '@/assets/images/home/visits.png'
export default defineComponent({
  components: {
    CountTo
  },
  props: {
    usertotal: {
      type: Number,
      required: true
    },
    devicetotal: {
      type: Number,
      required: true
    }
  },
  emits: ['handle-set-line-chart-data'],
  setup(_, { emit }) {
    const handleSetLineChartData = (type: string) => {
      emit('handle-set-line-chart-data', type)
    }

    const myCount = ref(null)
    return {
      handleSetLineChartData,
      myCount,
      messages,
      purchases,
      visits,
      shoppings
    }
  }
})

<!--chart
 * @Description: 首页折现图组件
 * @Author: ZY
 * @Date: 2021-01-18 09:31:32
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-26 10:04:16
-->
<template>
  <div
    id="homeLineCharts"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import { getdetaildata } from '../../../../apis/echartdata'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    year: {
      type: Number,
      default: 2022,
      required: true
    }
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const state = {
      thisdata: [] as Object[],
      devicedata: [] as String[]
    }
    const setOptions = (chartData: any) => {
      state.thisdata.length = 0
      state.devicedata.length = 0
      const color = ['#435EBE', '#5DDAB4', '#9694FF', '#FF7976', '#57CAEB']
      chartData.forEach((element: {cnname: string}, index: number) => {
        state.thisdata.push({
          name: element.cnname,
          itemStyle: {
            color: color[index],
            lineStyle: {
              color: color[index],
              width: 2
            }
          },
          smooth: true,
          type: 'line',
          data: [...element[props.year]],
          animationDuration: 2000,
          animationEasing: 'cubicInOut'
        })
        state.devicedata.push(element.cnname)
      })
      if (chart.value) {
        chart.value.setOption({
          xAxis: {
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
            boundaryGap: true,
            axisTick: {
              show: false
            }
          },
          grid: {
            left: 10,
            right: 10,
            bottom: 20,
            top: 30,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            padding: 8
          },
          yAxis: {
            axisTick: {
              show: false
            }
          },
          legend: {
            data: state.devicedata
          },
          series: state.thisdata
        } as EChartsOption)
      }
    }
    const getdetaildevice = async(type: number) => {
      await getdetaildata(type).then(res => {
        setTimeout(() => {
          setOptions(res?.data)
        }, 0)
      }).catch(err => {
        console.log(err)
      })
    }
    watch(() => props.year, (newvalue, oldvalue) => {
      if (oldvalue !== newvalue) {
        getdetaildevice(newvalue)
      }
    })
    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineCharts') as HTMLDivElement, 'macarons')
      chart.value = lineChart
      getdetaildevice(props.year)
    }
    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
      if (!chart.value) {
        return
      }
      chart.value.dispose()
      chart.value = null
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

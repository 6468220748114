/**
 * echarts图表数据接口
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
/**
 *
 * @returns 获取设备柱状图数据
 */
export const getBarchart = () => {
  return https(true).request<RootObject<Array<object>>>('/backstage/api/device/total/bar/chart', Method.GET, undefined, ContentType.form)
}
/**
 *
 * @returns 获取设备饼图数据
 */
export const getLinchart = () => {
  return https(true).request<RootObject<String>>('/backstage/api/device/total', Method.GET, undefined, ContentType.form)
}
/**
 *
 * @returns 用户饼图总数
 */
export const getUserData = () => {
  return https(true).request<RootObject<String>>('/backstage/api/device/user/total', Method.GET, undefined, ContentType.form)
}
/**
 *
 * @returns 用户详细数据
 */
export const getUserDetailData = (year: string) => {
  return https(true).request<RootObject<String>>(`/backstage/api/device/user/total/details/${year}`, Method.GET, undefined, ContentType.form)
}
/**
 * 设备详细数据
 * @returns
 */
export const getdetaildata = (year: number) => {
  return https(true).request<RootObject<String>>(`/backstage/api/device/total/details/${year}`, Method.GET, undefined, ContentType.form)
}

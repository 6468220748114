<!--
 * @Description: admin 权限主页
 * @Author: ZY
 * @Date: 2021-01-15 18:44:25
 * @LastEditors: SCY
 * @LastEditTime: 2021-04-02 09:40:07
-->
<template>
  <div
    class="dashboard-editor-container"
    v-loading="fullscreenLoading"
    element-loading-text="正在加载..."
  >
    <PanelGroup
      v-if="usertotal>0 && devicetotal>0"
      :usertotal="usertotal"
      :devicetotal="devicetotal"
    />
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;border-radius: 8px;">
      <div
        class="chart-title"
      >
        设备详细数据: {{ year }} 年
      </div>
      <LineChart
        :year="year"
      />
    </el-row>
    <el-row :gutter="32">
      <el-col
        :xs="24"
        :sm="24"
        :lg="8"
      >
        <div class="chart-wrapper">
          <div
            class="chart-title"
          >
            各设备总数
          </div>
          <PieChart
            v-if="deviceflag && personflag"
            id="homePieCharts"
            :devicestate="devicedata"
          />
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :lg="8"
      >
        <div class="chart-wrapper">
          <div
            class="chart-title"
          >
            设备绑定用户数
          </div>
          <PieChart
            v-if="deviceflag && personflag"
            id="PersonPieCharts"
            :personstate="persondata"
            :personcolor="personcolor"
          />
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :lg="8"
      >
        <div class="chart-wrapper">
          <div
            class="chart-title"
          >
            设备数据/年
          </div>
          <BarChart @handle-set-line-chart-data="handleSetLineChartData" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onBeforeMount, watchEffect, onMounted } from 'vue'
import PanelGroup from './components/PanelGroup.vue'
import LineChart from './components/LineChart.vue'
// import RadarChart from './components/RadarChart.vue'
import PieChart from './components/PieChart.vue'
import BarChart from './components/BarChart.vue'
import { getLinchart, getUserData } from '../../../apis/echartdata'
import { ElMessage } from 'element-plus'
export default {
  components: {
    PanelGroup,
    LineChart,
    // RadarChart,
    PieChart,
    BarChart
  },
  setup() {
    const state = reactive({
      devicedata: {},
      persondata: {},
      deviceflag: false,
      personflag: false,
      usertotal: 0,
      devicetotal: 0,
      year: 2022
    })
    const personcolor = ['#d4b4e2', '#ffc8ac', '#9fd8be', '#bee9f9', '#ffb7b7']
    const getAlldevicedata = async() => {
      await getLinchart().then((res) => {
        if (res?.code === 0) {
          state.devicedata = res.data
          state.deviceflag = true
          res.data.forEach(element => {
            state.devicetotal += element.total
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    const getAlluserdata = async() => {
      await getUserData().then((res) => {
        if (res?.code === 0) {
          state.persondata = res.data
          state.personflag = true
          res.data.forEach(element => {
            state.usertotal += element.total
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    const fullscreenLoading = ref(false)
    const handleSetLineChartData = (type) => {
      if (typeof type === 'number') {
        state.year = type
      }
      // lineChartData.value = data[type]
    }
    onBeforeMount(() => {
      fullscreenLoading.value = true
      getAlldevicedata()
      getAlluserdata()
    })
    onMounted(() => {
      let timer = null
      const setInterflag = setInterval(() => {
        watchEffect(() => {
          if (state.personflag === true) {
            fullscreenLoading.value = false
            clearInterval(setInterflag)
            ElMessage({
              showClose: true,
              message: '加载成功',
              center: true,
              type: 'success',
              duration: 1500
            })
          } else {
            fullscreenLoading.value = true
            if (timer) return
            timer = setTimeout(() => {
              fullscreenLoading.value = false
              ElMessage({
                showClose: true,
                message: '加载失败，请稍后再试',
                center: true,
                type: 'error',
                duration: 1500
              })
              clearInterval(setInterflag)
            }, 5000)
          }
        })
      }, 1000)
    })
    return {
      fullscreenLoading,
      personcolor,
      handleSetLineChartData,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: #F2F7FF;
  position: relative;

  .github-corner{
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }
  .chart-title {
    width: 100%;
    font-weight: 700;
    font-size: small;
    text-align: center;
    color:#000000;
    margin-bottom: 30px;
  }
  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
    border-radius: 8px;
  }
  .example-showcase .el-loading-mask {
  z-index: 9;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>

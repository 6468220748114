<!--
 * @Description: 首页柱形图小组件
 * @Author: ZY
 * @Date: 2021-01-15 18:48:40
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-26 09:57:33
-->

<template>
  <div
    id="homebarcharts"
    :class="className"
    :style="{height: height, width: width}"
    @click="handleSetLineChartData()"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, ref, reactive, toRefs } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import { getBarchart } from '../../../../apis/echartdata'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    }
  },
  emits: ['handle-set-line-chart-data'],
  setup(_, { emit }) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const animationDuration = 6000
    const state = reactive({
      nowtype: ref(''),
      handleSetLineChartData: () => {
        emit('handle-set-line-chart-data', Number(state.nowtype))
      },
      newdata: [] as any
    })
    const initChart = () => {
      const barChart = init(document.getElementById('homebarcharts') as HTMLDivElement, 'macarons')
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['2019', '2020', '2021', '2022', '2023'],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: state.newdata
      } as EChartsOption)
      chart.value = barChart
      barChart.on('click', (params: any) => {
        state.nowtype = params.name
      })
    }
    const getBarChart = async() => {
      await getBarchart().then((res) => {
        if (res?.code === 0) {
          const data = res.data
          data.forEach((item: any) => {
            const yeardata: number[] = []
            const ilength = item.detalyear.length
            const currenti = item.detalyear
            for (let i = 0; i < ilength; i++) {
              yeardata.unshift(Number(Object.values(currenti[i])[0]))
            }
            state.newdata.push({ name: item.cnname, type: 'bar', stack: 'vistors', data: yeardata, animationDuration })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
    onMounted(() => {
      mounted()
      nextTick(() => {
        getBarChart().then(() => {
          initChart()
        })
      })
    })

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      ...toRefs(state)
    }
  }
})
</script>

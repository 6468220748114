
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    id: {
      type: String,
      default: 'homePieCharts',
      required: true
    },
    devicestate: {
      type: Object,
      required: true
    },
    personstate: {
      type: Object,
      required: true
    },
    personcolor: {
      type: Array,
      required: true
    }
  },
  setup(_) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    const initChart = () => {
      const percolor = _.personcolor?.length === 0 ? ['#435EBE', '#5DDAB4', '#9694FF', '#FF7976', '#57CAEB'] : _.personcolor
      const newdata: any = []
      const devicename: String[] = []
      if (_.id === 'homePieCharts' && _.devicestate.length > 0) {
        _.devicestate.forEach((item: { cnname: string, total: number }) => {
          devicename.push(item.cnname)
          newdata.push({ value: item.total, name: item.cnname })
        })
      }
      if (_.id === 'PersonPieCharts' && _.personstate.length > 0) {
        _.personstate.forEach((item: { cnname: string, total: number }) => {
          devicename.push(item.cnname)
          newdata.push({ value: item.total, name: item.cnname })
        })
      }
      const pieChart = init(document.getElementById(_.id) as HTMLDivElement, 'macarons')
      pieChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: devicename
        },
        series: [
          {
            color: percolor,
            type: 'pie',
            name: '总数',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            data: newdata,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      } as EChartsOption)
      chart.value = pieChart
    }
    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
